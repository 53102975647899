<script lang="ts" setup>
import HomeContent from "~/components/home/HomeContent.vue";
import rawRedirect from "~/assets/js/redirectToUserStateHomepage?url";

// Check the user for a userState cookie to redirect them to the correct homepage
// this is run in raw JS so that it can start processing even before the app loads
// to allow the redirect to happen as early as possible and minimize flashing
useHead({
    script: [
        {
            src: rawRedirect,
            type: "module",
        },
    ],
});

// Change layout template
definePageMeta({
    layout: "no-container",
});

// Simple (useSeoMeta) or useServerSeoMeta
useSeoMeta({
    title: "American Book Company - K-12 books and online testing",
});
</script>

<template>
    <div>
        <HomeContent />
    </div>
</template>

<style scoped></style>
